<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <c-table
          ref="table"
          title="변수 목록"
          tableId="variable"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-md-12 col-lg-8">
        <q-form ref="editForm">
          <!-- 변수 상세 -->
          <c-card title="변수 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                      <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addData" />
                      <c-btn 
                  v-if="saveEditable" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="variable"
                  :mappingType="mappingType"
                  label="저장" 
                  icon="save" 
                  @beforeAction="saveVariable"
                  @btnCallback="saveVariableCallback"/>
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  :required="true"
                  label="변수"
                  name="variableName"
                  v-model="variable.variableName">
                </c-text>
              </div>
              <div class="col-6">
                <c-plant
                  :disabled="!saveMode"
                  :editable="editable"
                  :required="true"
                  type="edit" 
                  name="plantCd"
                  v-model="variable.plantCd" />
              </div>
              <div class="col-6">
                <!-- 사용여부 -->
                <c-radio
                  :disabled="!saveMode"
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="variable.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-tab
                  type="tabcard"
                  align="left"
                  :tabItems.sync="tabItems"
                  :inlineLabel="true"
                  :tabInCheck="true"
                  :disabled="!saveEditable"
                  v-model="tab"
                  @changeCheckbox="changeCheckbox"
                >
                  <template v-slot:default="tab">
                    <component
                      :is="tab.component"
                      :variable.sync="variable"
                      :ramVariableGuidewordCd="tab.name"
                      :disabled="!tab.check"
                    />
                  </template>
                </c-tab>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-guideword',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'variableName',
            field: 'variableName',
            // 변수
            label: '변수',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      useFlagItems: [],
      variable: {
        ramVariableId: '',  // 변수 번호
        plantCd: null,
        variableName: '',  // 변수명
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        variableGuidewords: [], // 사용 가이드워드 목록
        deleteVariableGuidewords: [], // [삭제] 사용 가이드워드 목록
      },
      tab: '',
      tabItems: [],
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      saveMode: false,
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabDisabled() {
      if (this.variable && this.variable.ramVariableId) {
        return false;
      } else {
        return true;
      }
    },
    saveEditable() {
      return this.editable && this.saveMode
    }
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.variable.list.url
      this.detailUrl = selectConfig.ram.variable.get.url
      this.saveUrl = transactionConfig.ram.variable.insert.url
      // code setting
      this.$comm.getComboItems('RAM_VARIABLE_GUIDEWORD_CD').then(_result => {
        this.tab = _result[0].code
        this.$_.forEach(_result, item => {
          this.tabItems.push({
            name: item.code, 
            label: item.codeName, 
            check: false, 
            component: () => import(`${'./hazopDeviationPosibleCause.vue'}`)
          })
        })
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.$http.url = this.$format(this.detailUrl, row.ramVariableId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.variable, _result.data);

        if (this.variable.variableGuidewords && this.variable.variableGuidewords.length > 0) {
          let data = this.$_.uniq(this.$_.map(this.variable.variableGuidewords, 'ramVariableGuidewordCd'))
          this.$_.forEach(this.tabItems, item => {
            if (this.$_.indexOf(data, item.name) > -1) {
              item.check = true;
            } else {
              item.check = false;
            }
          })
        } else {
          this.$_.forEach(this.tabItems, item => {
            item.check = false;
          })
        }

        this.saveMode = true;
      },);
    },
    changeCheckbox(val, tab) {
      if (!val) {
        this.variable.variableGuidewords = this.$_.reject(this.variable.variableGuidewords, { ramVariableGuidewordCd: tab.name })
      }
    },
    addData() {
      Object.assign(this.$data.variable, this.$options.data().variable);
      this.saveMode = true;
    },
    saveVariable() {
      if (this.variable.ramVariableId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.ram.variable.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.ram.variable.insert.url
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            // 가능한원인을 작성하지 않은 행은 저장하지 않습니다.\n\r저장하시겠습니까?
            message: '가능한원인을 작성하지 않은 행은 저장하지 않습니다.\n\r저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.variable.regUserId = this.$store.getters.user.userId
              this.variable.chgUserId = this.$store.getters.user.userId
              
              if (this.variable.variableGuidewords && this.variable.variableGuidewords.length > 0) {
                this.variable.variableGuidewords = this.$_.reject(this.variable.variableGuidewords, (item) => {
                  return !item.causeName
                })
              }
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveVariableCallback(result) {
      this.variable.ramVariableId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ ramVariableId: result.data });
    },
  }
};
</script>
